<div class="body">
  <img  src="./assets/images/img_interior_servicios.png" align="left" /><br /><h1 class="mainTitle mt-6 pt-6">Acerca de nosotros</h1>
  <h3 class="mt-5 ">Generamos soluciones a la medida de tus necesidades</h3>
  <p class="mt-5 ">Somos un equipo de profesionales con más de 10 años de experiencia, capacitados y especializados para bridar a nuestros clientes lo último en tecnología para el desarrollo de sus productos: aplicaciones, páginas web, sistemas, intranet y todo lo que puedan imaginar para el desarrollo de sus organizaciones. </p>

  <br>
  <div class="clear"></div>

  <app-footer-contact></app-footer-contact>
</div>
