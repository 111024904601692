<div
  class="opt"
  [ngStyle]="{'background-image': 'url(' + background + ')'}">
  <div class="layer">
    <div class="content">
      <ng-content></ng-content>
    </div>

  </div>

</div>

