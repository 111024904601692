import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {HomeComponent} from './pages/home/home.component';
import {MNU_MAIN} from './consts/mnu-main.const';
import {PaginaServiciosComponent} from './pages/pagina-servicios/pagina-servicios.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    component: AboutUsComponent,
    path: MNU_MAIN[0].link,
  },
  {
    component: PaginaServiciosComponent,
    path: MNU_MAIN[1].link,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
