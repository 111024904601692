<h3>CONTACTO</h3>
<div class="footer-contact">
  <ul>
    <li>
      <mat-icon class="icon" aria-hidden="false" aria-label="Cellphone">stay_current_portrait</mat-icon>
      <span>5548701020</span>
    </li>
    <li>
      <mat-icon class="icon" aria-hidden="false" aria-label="Email">mail_outline</mat-icon>
      <span>contacto@viancom.com</span>
    </li>
    <li>
      Horario de atención: Lunes - Viernes 8:00AM - 6:00PM
    </li>
  </ul>
</div>
