<div class="body">
  <lyt-slider>
    <div>
      <img src="assets/images/logo/logo_viancom_slider.png" />
      <div class="slide-text">TECNOLOGÍA EN TUS MANOS</div>
    </div>
  </lyt-slider>
  <app-servicios class="services"></app-servicios>
  <app-mnuprojects></app-mnuprojects>
  <br>
  <app-footer-contact></app-footer-contact>
</div>
