
export const MNU_MAIN = [
  {
    label: 'NOSOTROS',
    link: 'about-us'
  },
  {
    label: 'SERVICIOS',
    link: 'servicios'
  },
  {
    label: 'CONTACTO',
    link: ''
  }
];
