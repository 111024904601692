import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '../../projects/layout/src/lib/layout.module';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ServiciosItemComponent } from './components/servicios/subcomponents/servicios-item/servicios-item.component';
import { MnuprojectsComponent } from './components/mnuprojects/mnuprojects.component';
import { OptprojectComponent } from './components/mnuprojects/subcomponents/optproject/optproject.component';
import { FooterContactComponent } from './components/footer-contact/footer-contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeComponent } from './pages/home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { PaginaServiciosComponent } from './pages/pagina-servicios/pagina-servicios.component';


@NgModule({
  declarations: [
    AppComponent,
    ServiciosComponent,
    ServiciosItemComponent,
    MnuprojectsComponent,
    OptprojectComponent,
    FooterContactComponent,
    AboutUsComponent,
    HomeComponent,
    PaginaServiciosComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
