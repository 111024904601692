<div class="menu-projects">
  <div class="options">
    <app-optproject background="assets/images/sites/img_projects_01.png">
      <p><img src="assets/images/icons/icon_consultoria.png" alt=""> <br> CONSULTORIA</p>
    </app-optproject>
    <app-optproject background="assets/images/sites/img_projects_02.png">
      <p><img src="assets/images/icons/icon_apps.png" alt=""> <br> DESARROLLO DE APPS</p>
    </app-optproject>
    <app-optproject background="assets/images/sites/img_projects_03.png">
      <p><img src="assets/images/icons/icon_desarrollo_software.png" > <br> DESARROLLO DE SOFTWARE</p>
    </app-optproject>
  </div>
  <div class="allprojects">
    VER TODOS LOS PROYECTOS
  </div>

</div>
