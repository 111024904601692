import { Injectable } from '@angular/core';
import {MNU_MAIN} from '../consts/mnu-main.const';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  mnuMain = MNU_MAIN;

  constructor() { }
}
