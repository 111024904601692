import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-optproject',
  templateUrl: './optproject.component.html',
  styleUrls: ['./optproject.component.scss']
})
export class OptprojectComponent implements OnInit {
  @Input() background;

  constructor() { }

  ngOnInit(): void {


  }

}
