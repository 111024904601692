<div class="body">
  <img  src="./assets/images/img_interior_servicios.png" align="left" /><br /><h1 class="mainTitle mt-6 pt-6">Servicios</h1>
  <p><b>Desarrollo de Software:</b> Desarrollamos software a tu medida, trabajando hombro a hombro contigo para desarrollar el sistema que tu organización necesita para su optimo funcionamiento.</p>

  <p><b>Desarrollo de APPS:</b> El mundo está en las manos de los consumidores y tú necesitas llegar a ellos, nosotros te ayudamos a ellos, desarrollando contigo las aplicaciones para que estés al en las manos de tus clientes, mediante una aplicación móvil.
  </p>
  <p>
    <b>Consultoría:</b> ¿Necesitas ayuda con la tecnología? ¿Necesitas que te asesoren con tu imagen digital? Tenemos la solución a tu alcance… contamos con los mejores profesionales para asesorarte o crear una campaña de marketing web completa.
  </p>
  <p>
    <b>Diseño gráfico:</b> ¿Necesitas un diseñador gráfico web? Nosotros podemos asesorarte para que logres tener la imagen de impacto que necesitas para llegar a más clientes.ng
  </p>
  <br>
  <div class="clear"></div>

  <app-footer-contact></app-footer-contact>
</div>
