<app-servicios-item icon="assets/images/icons/icon_desarrollo_software.png">
  <p class="title">DESARROLLO DE SOFTWARE</p>
  <p class="desc">Generamos soluciones a la medida de tus necesidades</p>
</app-servicios-item>
<app-servicios-item icon="assets/images/icons/icon_apps.png">
  <p class="title">DESARROLLO DE APPS</p>
  <p class="desc">
    Desarrollamos para las plataformas iOS y Android
  </p>
</app-servicios-item>
<app-servicios-item icon="assets/images/icons/icon_consultoria.png">
  <p class="title">CONSULTORIA</p>
  <p class="desc">Seamos un equipo y crece tu negocio</p>
</app-servicios-item>
<app-servicios-item icon="assets/images/icons/icon_diseno_grafico.png">
  <p class="title">DISEÑO GRAFICO</p>
  <p class="desc">Mensajes visuales de impacto creados por profesionales</p>
</app-servicios-item>
