import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mnuprojects',
  templateUrl: './mnuprojects.component.html',
  styleUrls: ['./mnuprojects.component.scss']
})
export class MnuprojectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
