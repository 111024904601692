<div id="logo">
  <a routerLink="/" title="Home"><img src="{{logo}}" /></a>
</div>
<nav class="mnuBar">
  <ul>
    <li *ngFor="let item of menu">
      <a routerLink="{{item.link}}">
        {{item.label}}
      </a>
    </li>
  </ul>
</nav>


