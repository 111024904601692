import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../models/menu-item.model';

@Component({
  selector: 'lyt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('logo') logo;
  @Input('menu') menu: MenuItem[];
  constructor() { }

  ngOnInit(): void {
  }

}
